import { autoinject, TaskQueue, bindable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { IAppointmentRequest, IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import "flatpickr/dist/flatpickr.css";
import { Router } from "aurelia-router";
import { DialogService } from "aurelia-dialog";
import { NewAppointmentFromRequestDialog } from "modals/new-appointment-from-request";
import Swal from "sweetalert2";

@connectTo<State>()
@autoinject()
export class AppointmentRequests {
  _stateManager: StateManager;
  _router: Router;
  dialogService: DialogService;

  appointmentRequests: IAppointmentRequest[];
  user: IUser;

  isLoadingTableData: boolean = false;

  constructor(
    stateManager: StateManager,
    taskQueue: TaskQueue,
    dialogService: DialogService,
    router: Router
  ) {
    this._stateManager = stateManager;
    this.dialogService = dialogService;
    this._router = router;
  }

  stateChanged(state) {
    this.appointmentRequests = state.patientAppointmentRequests;
    this.isLoadingTableData = false;
  }

  attached() {
    this.isLoadingTableData = true;
    this._stateManager.getPatientAppointmentRequests();
  }

  navigateToPatient(user) {
    this._router.navigateToRoute("patient", { userId: user.userId });
  }

  deleteAppointmentRequest(appointmentRequest: IAppointmentRequest) {
    Swal.fire({
      title: `Confirm Deletion`,
      text: `Are you sure you want to delete ${appointmentRequest.user.firstName}'s appointment request?`,
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `Yes, Delete`,
      confirmButtonColor: "#40684e",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await this._stateManager.deleteAppointmentRequest(appointmentRequest);
        await this._stateManager.getPatientAppointmentRequests();
      }
    });
  }

  createAppointment(appointmentRequest: IAppointmentRequest) {
    this.dialogService
      .open({
        viewModel: NewAppointmentFromRequestDialog,
        model: appointmentRequest,
        lock: false,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
          await this._stateManager.postAppointmentAsync(response.output);
          await this._stateManager.getPatientAppointmentRequests();
        } else {
        }
      });
  }
}
