import { autoinject, TaskQueue } from "aurelia-framework";
import { Router } from "aurelia-router";
import { AuthenticationService } from "services/authentication-service";
import Swal from "sweetalert2";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { DialogService } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { ISubscription, ISubscriptionType, IUser } from "services/interfaces";
import { connectTo } from "aurelia-store";
import { State } from "services/state";

@connectTo<State>()
@autoinject()
export class NewClientDialog {
  dialogService: DialogService;
  router: Router;
  private _authenticationService: AuthenticationService;
  taskQueue: TaskQueue;
  flatPickerObject: any;
  stateManager: StateManager;
  createdUser: IUser = null;
  newSavedCreditCard: any = null;
  currentView: string = "addClient";
  subscriptionTypes: any[] = [];
  displaySubscriptionType: ISubscription;
  subscription: ISubscription = {
    subscriptionTypeId: "",
    userId: "",
    isAnnual: true,
    startDate: new Date(),
    isActive: false,
  };

  newCreditCard: any = {
    cardNumber: "",
    creditCardType: "",
    yearExpiration: "",
    monthExpiration: "",
    cv2: "",
    billingContact: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      streetNumber: "",
      street: "",
      postalCode: "",
    },
  };

  showingRegisterErrors: boolean = false;
  showingCreditCardErrors: boolean = false;

  attemptingRegistration: boolean = false;
  addingNewCreditCard: boolean = false;
  purchasingSubscription: boolean = false;

  registerFirstName: string = "";
  firstNameError: string = "";

  registerLastName: string = "";
  lastNameError: string = "";

  registerEmail: string = "";
  emailError: string = "";

  registerPhoneNumber: string = "";
  phoneNumberError: string = "";

  registerWantsNewsletter: boolean = true;
  registerServerMessage: string = "";
  creditCardServerMessage: string = "";
  membershipServerMessage: string = "";

  birthday: Date;
  birthdayError: string = "";

  sendConfirmationEmailBool: boolean = false;

  constructor(
    authenticationService: AuthenticationService,
    router: Router,
    taskQueue: TaskQueue,
    dialogService: DialogService,
    stateManager: StateManager
  ) {
    this._authenticationService = authenticationService;
    this.router = router;
    this.taskQueue = taskQueue;
    this.dialogService = dialogService;
    this.stateManager = stateManager;
  }

  async attached() {
    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
    await this.stateManager.getSubscriptionTypesAsync();
  }

  stateChanged(state: State) {
    this.subscriptionTypes = state.subscriptionTypes;
    if (!this.displaySubscriptionType) {
      this.displaySubscriptionType = state.subscriptionTypes.find(
        (st) => st.name == "Founder"
      );
    }
  }

  selectSubscriptionType(nameString) {
    this.displaySubscriptionType = this.subscriptionTypes.find(
      (st) => st.name == nameString
    );
  }

  createDatePicker() {
    var htmlElement = document.getElementById("birthday-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      dateFormat: "F d",
      onChange: (date) => {
        this.birthday = date[0];
      },
    });
  }

  async registerNewUser() {
    if (!this.registerFirstName) {
      this.firstNameError = "Please enter your first name";
    } else {
      this.firstNameError = "";
    }

    if (!this.registerLastName) {
      this.lastNameError = "Please enter your last name";
    } else {
      this.lastNameError = "";
    }

    this.checkEmailAddress();

    this.checkPhoneNumber();

    if (
      this.firstNameError ||
      this.lastNameError ||
      this.emailError ||
      this.phoneNumberError
    ) {
      this.showingRegisterErrors = true;
      return;
    }

    this.attemptingRegistration = true;

    const returnedData = await fetch("ShallowRegister", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: this.registerEmail,
        phone: this.registerPhoneNumber,
        firstName: this.registerFirstName,
        lastName: this.registerLastName,
        birthday: this.birthday,
        sendConfirmationEmail: this.sendConfirmationEmailBool,
      }),
    });

    const registerResult = await returnedData.json();

    if (registerResult == "Email Taken") {
      this.registerServerMessage = "Email already in use";
      this.attemptingRegistration = false;
      return;
    }
    if (registerResult == "Failed To Create User") {
      this.registerServerMessage =
        "Failed to create account. Please try again later";
      this.attemptingRegistration = false;
      return;
    }

    if (registerResult == "User Already Exists") {
      this.registerServerMessage = "That email is already in use.";
      this.attemptingRegistration = false;
      return;
    }

    if (registerResult.userId != null) {
      this.createdUser = registerResult;
      this.registerServerMessage = "Account Successfully Created!";
      this.attemptingRegistration = false;
      this.stateManager.getPatientsAsync(null);
    }
  }

  async submitCardInfo() {
    this.addingNewCreditCard = true;
    this.newCreditCard.creditCardType = this.getCreditCardType(
      this.newCreditCard
    );
    const fetchUrl = `/patientcreditcards`;
    const returnedData = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
      body: JSON.stringify({
        user: this.createdUser,
        creditCard: this.newCreditCard,
      }),
    });

    try {
      const data = await returnedData.json();
      this.newSavedCreditCard = data;
      console.log("Credit Card Returned Data", data);
      this.addingNewCreditCard = false;
    } catch (exception) {
      console.log("Error");
    }
  }

  toggleNewsletter() {
    this.registerWantsNewsletter = !this.registerWantsNewsletter;
  }

  checkPhoneNumber() {
    if (!this.registerPhoneNumber) {
      this.phoneNumberError = "Please enter your phone number";
      return;
    }

    var phoneRegex = /^[0-9]{10}$/;
    if (this.registerPhoneNumber.match(phoneRegex)) {
      this.phoneNumberError = "";
    } else {
      this.phoneNumberError = "Please enter a valid 10-digit phone number";
    }
  }

  checkEmailAddress() {
    if (!this.registerEmail) {
      this.emailError = "Please enter your email address";
      return;
    }

    if (
      this.registerEmail.includes("@") === false ||
      this.registerEmail.includes(".") === false
    ) {
      this.emailError = "Please enter a valid email address";
      return;
    }
    this.emailError = "";
  }

  navigateToLoginPage() {
    this.router.navigateToRoute("login");
  }

  getCreditCardType(creditCard) {
    switch (creditCard.cardNumber[0]) {
      case "4":
        return "Visa";
      case "5":
        return "MasterCard";
      case "3":
        return "Amex";
      default:
        return "Unknown";
    }
  }
  fillCardholderInformation() {
    this.newCreditCard.billingContact.firstName = this.createdUser.firstName;
    this.newCreditCard.billingContact.lastName = this.createdUser.lastName;
    this.newCreditCard.billingContact.email = this.createdUser.email;
    this.newCreditCard.billingContact.phoneNumber =
      this.createdUser.mobilePhone;
  }

  sendConfirmationEmail() {
    Swal.fire({
      title: "Enter your Email Address",
      input: "email",
      inputPlaceholder: "janero@gmail.com",
      confirmButtonColor: "#40684e",
    }).then((result) => {
      if (result.value) {
        this._authenticationService.sendConfirmationEmail(result.value);
        Swal.fire({
          title: "Account Confirmation",
          text: "If that email has an account with us, you will receive a confirmation email shortly.",
          icon: "success",
          iconColor: "#40684e",
        });
      }
    });
  }
  closeDialog() {
    this.dialogService.closeAll();
  }

  async purchase() {
    this.purchasingSubscription = true;
    this.subscription.userId = this.createdUser.userId;
    this.subscription.subscriptionTypeId =
      this.displaySubscriptionType.subscriptionTypeId;

    await this.stateManager.postSubscriptionAsync({
      subscription: this.subscription,
      user: this.createdUser,
    });
    this.purchasingSubscription = false;

    this.closeDialog();
  }
}
