import {
  ICreditCard,
  IUser,
  ISubscription,
  ISubscriptionType,
} from "./../services/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";
import { State } from "services/state";
import { StateManager } from "services/state-manager";

@connectTo<State>()
@autoinject()
export class BuySubscriptionDialog {
  controller: DialogController;
  _authenticationService: AuthenticationService;
  _stateManager: StateManager;

  subscriptionType: ISubscriptionType;
  savedCreditCards: any[];
  user: IUser;
  purchasingSubscription: boolean = false;

  defaultCreditCard: ICreditCard;

  subscription: ISubscription = {
    subscriptionTypeId: "",
    userId: "",
    isAnnual: true,
    startDate: new Date(),
    isActive: false,
  };

  get subtotal() {
    if (this.subscription.isAnnual) {
      return this.subscriptionType.annualPrice;
    } else {
      return this.subscriptionType.monthlyPrice;
    }
  }
  get tax() {
    return this.subtotal * 0.05;
  }
  get total() {
    return this.subtotal + this.tax;
  }

  constructor(
    controller: DialogController,
    authenticationService: AuthenticationService,
    stateManager: StateManager
  ) {
    this.controller = controller;
    this._authenticationService = authenticationService;
    this._stateManager = stateManager;
  }

  activate(subscriptionType: ISubscriptionType) {
    this.subscriptionType = subscriptionType;
    this.subscription.subscriptionTypeId = subscriptionType.subscriptionTypeId;
  }

  stateChanged(state: State) {
    this.savedCreditCards = state.savedCreditCards;
    this.user = state.user;
    this.subscription.userId = this.user.userId;
    this.defaultCreditCard = this.savedCreditCards.find(
      (cc) => cc.isDefault === true
    );
  }

  attached() {
    if (this.savedCreditCards.length === 0) {
      this._stateManager.getSavedCreditCardsAsync();
    }
  }

  async purchase() {
    this.purchasingSubscription = true;
    await this._stateManager.postSubscriptionAsync(this.subscription);
    await this._stateManager.getSubscriptionAsync();
    await this._stateManager.getUserAsync();
    this.purchasingSubscription = false;
    this.controller.ok();
  }

  cancel() {
    this.controller.cancel();
  }
}
