import { IAppointment } from "./../services/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController, DialogService } from "aurelia-dialog";

import "flatpickr/dist/flatpickr.css";
import { StateManager } from "services/state-manager";

@autoinject()
export class CancelAppointmentDialog {
  controller: DialogController;
  _dialogService: DialogService;
  _stateManager: StateManager;

  appointment: IAppointment;
  cancellationReason: string;

  constructor(controller: DialogController, stateManager: StateManager) {
    this.controller = controller;
    this._stateManager = stateManager;
  }

  activate(appointment: IAppointment) {
    this.appointment = appointment;
  }

  async save() {
    this.appointment.isCancelled = true;
    this.appointment.cancelledReason = this.cancellationReason;

    await this._stateManager.updateAppointmentAsync(this.appointment);
    await this._stateManager.getTodaysAppointments();
    this.controller.ok();
  }

  cancel() {
    this.appointment.isCancelled = false;
    this.controller.cancel();
  }
}
