import { IAppointment, IAppointmentRequest } from "./../services/interfaces";
import { autoinject, observable, TaskQueue } from "aurelia-framework";
import { DialogController, DialogService } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { StateManager } from "services/state-manager";
import moment from "moment-timezone";
import Swal from "sweetalert2";

@autoinject()
export class EditAppointmentDialog {
  controller: DialogController;
  _authenticationService: AuthenticationService;
  _dialogService: DialogService;
  _stateManager: StateManager;
  flatPickerObject: any;
  taskQueue: TaskQueue;

  appointment: IAppointment;

  constructor(
    controller: DialogController,
    authenticationService: AuthenticationService,
    taskQueue: TaskQueue,
    stateManager: StateManager
  ) {
    this.controller = controller;
    this._authenticationService = authenticationService;
    this.taskQueue = taskQueue;
    this._stateManager = stateManager;
  }

  activate(appointment: IAppointment) {
    this.appointment = appointment;
  }

  attached() {
    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
  }

  createDatePicker() {
    var htmlElement = document.getElementById("new-appointment-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      altInput: true,
      altFormat: "F j, Y",
      defaultDate: this.appointment.date,
      minDate: "today",
      enableTime: true,

      onChange: (date) => {
        this.appointment.date = moment(date[0])
          .tz("America/Edmonton", true)
          .toDate();
      },
    });
  }

  async save() {
    await this._stateManager.updateAppointmentAsync(this.appointment);
    await this._stateManager.getAllAppointmentsAsync();
    this.controller.ok();
  }
  cancelAppointment() {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to cancel this appointment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, cancel it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this.appointment.isCancelled = true;
        this.save();
      }
    });
  }

  cancel() {
    this.controller.cancel();
  }
}
