import { autoinject, TaskQueue, bindable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { IAppointment, IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import "flatpickr/dist/flatpickr.css";
import { Router } from "aurelia-router";
import moment from "moment";
import { DialogService } from "aurelia-dialog";
import { EditAppointmentDialog } from "modals/edit-appointment";
import { NewAppointmentDialog } from "modals/new-appointment";
import { CompleteMemberAppointmentDialog } from "modals/complete-member-appointment";
import { CancelAppointmentDialog } from "modals/cancel-appointment";
import Swal from "sweetalert2";

@connectTo<State>()
@autoinject()
export class PatientAppointments {
  _stateManager: StateManager;
  _router: Router;
  _dialogService: DialogService;
  appointments: IAppointment[];
  isLoadingTableData: boolean = false;

  constructor(
    stateManager: StateManager,
    router: Router,
    dialogService: DialogService
  ) {
    this._stateManager = stateManager;
    this._router = router;
    this._dialogService = dialogService;
  }

  stateChanged(state: State) {
    this.appointments = state.allAppointments;
    this.isLoadingTableData = false;
  }

  attached() {
    this.isLoadingTableData = true;
    this._stateManager.getAllAppointmentsAsync();
  }

  navigateToPatient(user: IUser) {
    this._router.navigateToRoute("patient", { userId: user.userId });
  }

  generateComortPreferences(user: IUser) {
    var comfortPreferences = "";
    if (user.soundCancellingHeadphones)
      comfortPreferences += "Sound Cancelling Headphones, ";
    if (user.netflix) comfortPreferences += "Netflix, ";
    if (user.lemongrassDiffuser) comfortPreferences += "Lemongrass Diffuser, ";
    if (user.weightedBlanket) comfortPreferences += "Weighted Blanket, ";
    if (user.stressBalls) comfortPreferences += "Stress Balls, ";
    if (user.heatedBlanket) comfortPreferences += "Heated Blanket, ";
    if (user.latte) comfortPreferences += "Latte, ";
    if (user.waterBottle) comfortPreferences += "Water Bottle, ";
    if (user.eyeMask) comfortPreferences += "Eye Mask, ";

    //remove last comma from the list if there is one.
    if (comfortPreferences.length > 0)
      comfortPreferences = comfortPreferences.slice(0, -2);

    return comfortPreferences;
  }

  appointmentIsToday(appointment) {
    var appointmentDate = moment.utc(appointment.date).local().date();
    var today = moment().date();

    return appointmentDate === today;
  }
  openEditAppointmentModal(appointment: IAppointment) {
    this._dialogService
      .open({
        viewModel: EditAppointmentDialog,
        model: appointment,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }
  openNewAppointmentModal() {
    this._dialogService
      .open({
        viewModel: NewAppointmentDialog,
        model: {},
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }
  completeAppointment(appointment: IAppointment) {
    if (appointment.user.activeSubscription == null) {
      Swal.fire({
        title: "Complete Appointment?",
        text: "Are you sure you want to complete this appointment?",
        icon: "success",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Mark Complete",
        confirmButtonColor: "#40684e",
        denyButtonText: "Mark No Show",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-3",
          denyButton: "order-2",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          appointment.isCompleted = true;
          this._stateManager.updateAppointmentAsync(appointment);
        }
        if (result.isDenied) {
          appointment.isNoShow = true;
          this._stateManager.updateAppointmentAsync(appointment);
        }
      });
    } else {
      this._dialogService
        .open({
          viewModel: CompleteMemberAppointmentDialog,
          model: appointment,
        })
        .whenClosed(async (response) => {
          if (!response.wasCancelled) {
          } else {
          }
        });
    }
  }
  cancelAppointment(appointment) {
    this._dialogService
      .open({
        viewModel: CancelAppointmentDialog,
        model: appointment,
        lock: false,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }

  appointmentInFuture(appointment) {
    var appointmentDate = moment.utc(appointment.date);
    var currentDate = moment.utc();
    if (appointmentDate.isAfter(currentDate)) {
      return true;
    } else {
      return false;
    }
  }

  appointmentInPast(appointment) {
    var appointmentDate = moment.utc(appointment.date);
    var currentDate = moment.utc();
    if (appointmentDate.isBefore(currentDate)) {
      return true;
    } else {
      return false;
    }
  }
}
