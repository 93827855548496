import { autoinject, TaskQueue, bindable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import {
  IAppointment,
  IAppointmentRequest,
  IAppointmentType,
  IUser,
} from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import Swal from "sweetalert2";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { Router } from "aurelia-router";
import moment from "moment-timezone";

@connectTo<State>()
@autoinject()
export class Appointments {
  _stateManager: StateManager;
  @bindable _router: Router;

  appointmentRequests: IAppointmentRequest[];
  appointments: IAppointment[];
  user: IUser;

  isLoadingTableData: boolean = false;

  appointmentTypes: IAppointmentType[];
  appointmentRequest: IAppointmentRequest = {
    dateCreated: new Date(),
    appointmentType: null,
    requestedAppointmentDates: [],
    requestedAppointmentTimesOfDay: [],
    user: null,
  };
  submittingAppointmentRequest: boolean = false;

  taskQueue: TaskQueue;
  flatPickerObject: any;
  requestedAppointmentDates: Date[] = [];
  selectedAppointmentType: any;
  timeOfDaySelected: boolean = false;
  requestError: string = "";

  timesOfDay = [
    { id: 1, name: "Mornings", isSelected: false },
    { id: 2, name: "Afternoons", isSelected: false },
    { id: 3, name: "Evenings", isSelected: false },
  ];

  constructor(stateManager: StateManager, taskQueue: TaskQueue) {
    this._stateManager = stateManager;
    this.taskQueue = taskQueue;
  }

  stateChanged(state) {
    this.appointmentRequests = state.appointmentRequests;
    this.appointmentTypes = state.appointmentTypes;
    this.user = state.user;
    this.appointments = state.appointments;
  }

  activate(params, routeConfig, navigationInstruction) {
    console.log("Appointments Page Activated");
  }

  bind(bindingContext: Object, overrideContext: Object) {
    console.log("Appointments Page Binded");
  }

  attached() {
    console.log("Appointments Page Attached");

    this._stateManager.getAppointmentRequests();
    this._stateManager.getAppointmentTypesAsync();
    this._stateManager.getAppointments();
  }

  selectAppointment(appointment) {
    this.selectedAppointmentType = null;

    if (this.flatPickerObject != null) {
      this.flatPickerObject.destroy();
      this.flatPickerObject = null;
    }

    this.appointmentTypes.forEach((a) => {
      a.isSelected = false;
    });

    appointment.isSelected = true;
    this.selectedAppointmentType = appointment;

    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
  }

  selectTimeOfDay(timeOfDay) {
    this.requestError = "";
    timeOfDay.isSelected = !timeOfDay.isSelected;
    this.timeOfDaySelected = this.timesOfDay.some((t) => t.isSelected == true);
  }

  async requestAppointment() {
    //if there is no time of day selected, return.
    const timeOfDay = this.timesOfDay.filter((t) => t.isSelected).length === 0;
    if (timeOfDay) {
      this.requestError = "Please select a time of day.";
      return;
    }

    this.requestError = "";

    this.appointmentRequest.user = this.user;
    this.appointmentRequest.requestedAppointmentDates =
      this.requestedAppointmentDates.map((date) => {
        return {
          date: moment(date).tz("America/Edmonton", true).toDate(),
        };
      });

    this.appointmentRequest.appointmentType = this.selectedAppointmentType;
    this.appointmentRequest.requestedAppointmentTimesOfDay = this.timesOfDay
      .filter((t) => t.isSelected)
      .map((t) => {
        return { timeOfDay: t.name };
      });

    this.submittingAppointmentRequest = true;
    await this._stateManager.postAppointmentRequest(this.appointmentRequest);
    this.submittingAppointmentRequest = false;
    await this._stateManager.getAppointmentRequests();

    //Swal Fire Success Message.
    Swal.fire({
      title: `Request Submitted`,
      text: `Thank you for your request. We will reach out soon to book you in.`,
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `Great!`,
      confirmButtonColor: "#40684e",
    }).then(async (result) => {
      if (result.isConfirmed) {
        this._router.navigateToRoute("appointments");
      }
    });

    this.requestedAppointmentDates = [];
    this.flatPickerObject.destroy();
    this.flatPickerObject = null;
    this.selectedAppointmentType = null;
    this.appointmentTypes.forEach((a) => {
      a.isSelected = false;
    });
    this.timesOfDay.forEach((t) => {
      t.isSelected = false;
    });
  }

  createDatePicker() {
    var htmlElement = document.getElementById("appointment-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      altInput: true,
      altFormat: "F j, Y",
      mode: "multiple",
      inline: true,
      minDate: "today",
      onChange: (newDates, dateStr, instance) => {
        this.requestedAppointmentDates = newDates;
      },
    });
  }

  deleteAppointmentRequest(appointmentRequest) {
    Swal.fire({
      title: `Would you like to delete this request?`,
      text: `This action cannot be undone.`,
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Yes, Delete`,
      cancelButtonText: "Go Back",
      confirmButtonColor: "#40684e",
    }).then(async (result) => {
      if (result.isDenied) {
        await this._stateManager.deleteAppointmentRequest(appointmentRequest);
        this.isLoadingTableData = true;
        await this._stateManager.getAppointmentRequests();
        this.isLoadingTableData = false;
      }
    });
  }
}
