import { IAppointment, IService } from "../services/interfaces";
import { autoinject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { StateManager } from "services/state-manager";
import { State } from "services/state";
import { connectTo } from "aurelia-store";
import moment from "moment-timezone";

@connectTo<State>()
@autoinject()
export class CompleteMemberAppointmentDialog {
  controller: DialogController;
  stateManager: StateManager;

  subscriptionTypeId: string;
  userId: string;

  appointment: IAppointment;
  services: IService[];
  completedServices: IService[] = [];
  subscriptionServiceList: any[] = [];

  dropdownExpanded: boolean = false;

  constructor(controller: DialogController, stateManager: StateManager) {
    this.controller = controller;
    this.stateManager = stateManager;
  }

  selectOption(service: IService) {
    if (this.completedServices.find((ss) => ss.serviceId == service.serviceId))
      return;

    service.isSelected = true;
    this.completedServices.push(service);
  }
  toggleDropdown() {
    this.dropdownExpanded = !this.dropdownExpanded;
  }
  activate(appointment: IAppointment) {
    this.appointment = appointment;
    this.subscriptionTypeId =
      appointment.user.activeSubscription.subscriptionTypeId;
    this.userId = appointment.user.userId;
  }
  async attached() {
    this.stateManager.getServicesAsync();
  }

  stateChanged(state: State) {
    this.services = state.services;
  }

  async save() {
    const userId = this.userId;
    const subscriptionTypeId = this.subscriptionTypeId;
    this.completedServices.forEach((service) => {
      this.subscriptionServiceList.push({
        subscriptionTypeId: subscriptionTypeId,
        userId: userId,
        serviceId: service.serviceId,
      });
    });

    this.appointment.isCompleted = true;

    await this.stateManager.updateAppointmentAsync(this.appointment);

    await this.stateManager.postSubscriptionServicesAsync(
      this.subscriptionServiceList
    );

    await this.stateManager.getAllAppointmentsAsync();

    this.controller.ok();
  }

  cancel() {
    this.controller.cancel();
  }

  isServiceInList(service) {
    if (this.completedServices.find((ss) => ss.serviceId == service.serviceId))
      return "disabled";

    return "";
  }
  removeService(service) {
    service.isSelected = false;
    this.completedServices = this.completedServices.filter(
      (ss) => ss.serviceId != service.serviceId
    );
  }
  getBadgeColor(subscriptionType) {
    if (subscriptionType == "Founder") return "badge-ro-orange";
  }
}
