import { DialogService } from "aurelia-dialog";
import { autoinject } from "aurelia-framework";
import { ISubscription } from "aurelia-router";
import { connectTo } from "aurelia-store";
import { BuySubscriptionDialog } from "modals/buy-subscription";
import { NewCreditCardDialog } from "modals/new-credit-card";
import { AuthenticationService } from "services/authentication-service";
import { IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import Swal from "sweetalert2";

@connectTo<State>()
@autoinject()
export class Dashboard {
  _dialogService: DialogService;
  _authenticationService: AuthenticationService;
  _stateManager: StateManager;

  stateUser: IUser;
  displayUser: IUser;

  savedCreditCards: any[];
  subscriptionTypes: any[];
  displaySubscriptionType: ISubscription;
  subscription: ISubscription;

  editingUserInfo: boolean = false;
  savingUserInfo: boolean = false;

  editingBillingInfo: boolean = false;

  showingCreditCardForm: boolean = false;
  updatingCreditCard: boolean = false;
  removingCreditCard: boolean = false;

  editingComfortPreferences: boolean = false;
  savingComfortPreferences: boolean = false;

  showingServices: boolean = true;

  comfortPreferences: any[] = [
    { name: "Sound Cancelling Headphones", isSelected: true },
    { name: "Netflix", isSelected: true },
    { name: "Lemongrass Diffuser", isSelected: false },
    { name: "Weighted Blanket", isSelected: true },
    { name: "Stress Balls", isSelected: false },
    { name: "Warm, Heated Blanket", isSelected: false },
    { name: "Latte", isSelected: true },
    { name: "Bottle of Water", isSelected: true },
    { name: "Eye Mask", isSelected: false },
  ];

  comfortNotes = "Oat latte, please!";

  constructor(
    authenticationService: AuthenticationService,
    stateManager: StateManager,
    dialogService: DialogService
  ) {
    this._authenticationService = authenticationService;
    this._stateManager = stateManager;
    this._dialogService = dialogService;
  }

  stateChanged(state: State) {
    this.stateUser = state.user;
    this.displayUser = structuredClone(state.user);

    // console.log(state.savedCreditCards);

    this.savedCreditCards = state.savedCreditCards;
    // console.log("SCC", this.savedCreditCards);
    this.subscriptionTypes = state.subscriptionTypes;
    if (!this.displaySubscriptionType) {
      this.displaySubscriptionType = state.subscriptionTypes.find(
        (st) => st.name == "Founder"
      );
    }
    this.subscription = state.subscription;
  }

  async activate() {
    this._stateManager.getUserAsync();
    this._stateManager.getSavedCreditCardsAsync();
    this._stateManager.getSubscriptionTypesAsync();
    this._stateManager.getSubscriptionAsync();
  }
  async detached() {
    this.savedCreditCards = [];
    console.log("Dashboard detached");
  }
  unbind() {
    console.log("Dashboard unbind");
  }

  openNewCardModal() {
    this._dialogService
      .open({
        viewModel: NewCreditCardDialog,
        model: this.stateUser,
        lock: false,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
          this.editingBillingInfo = false;
        } else {
        }
      });
  }

  openPurchaseModal(subscriptionType) {
    if (this.savedCreditCards.length === 0) {
      Swal.fire({
        title: `No credit card on file`,
        text: `Please add a credit card before purchasing a subscription.`,
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `Got it!`,
        confirmButtonColor: "#40684e",
      }).then(async (result) => {});
      return;
    }
    this._dialogService
      .open({
        viewModel: BuySubscriptionDialog,
        model: subscriptionType,
        lock: false,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }

  async submitCardInfo(newCreditCard) {
    const fetchUrl = `/creditcards`;
    const returnedData = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
      body: JSON.stringify(newCreditCard),
    });

    try {
      const data = await returnedData.json();
    } catch (exception) {}

    await this._stateManager.getSavedCreditCardsAsync();
  }

  async removeCreditCard(creditCard) {
    if (
      this.savedCreditCards.length == 1 &&
      this.displayUser.activeSubscription != null
    ) {
      Swal.fire({
        title: `Card Removal Error`,
        text: `You must have at least one credit card on file while your subscription is active.`,
        showDenyButton: false,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: `Got it!`,
        confirmButtonColor: "#40684e",
      });
      return;
    }
    Swal.fire({
      title: `Would you like to remove this credit card?`,
      text: `Removing card ending in ${creditCard.creditCardLast4Digits}`,
      showDenyButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      denyButtonText: `Yes, Remove`,
      cancelButtonText: "Go Back",
      confirmButtonColor: "#40684e",
    }).then(async (result) => {
      if (result.isDenied) {
        this.removingCreditCard = true;
        await this._stateManager.removeSavedCreditCardAsync(creditCard);
        await this._stateManager.getSavedCreditCardsAsync();
        this.removingCreditCard = false;
      }
    });
  }

  editUserInfo() {
    this.editingUserInfo = true;
  }
  async saveUserInfo() {
    this.savingUserInfo = true;
    await this._stateManager.updateUserAsync(this.displayUser);
    this.editingUserInfo = false;
    this.savingUserInfo = false;
  }
  cancelUserSave() {
    this.displayUser = structuredClone(this.stateUser);
    this.editingUserInfo = false;
  }

  toggleBillingInfoEdit() {
    this.editingBillingInfo = !this.editingBillingInfo;
  }

  editPreferences() {
    this.editingComfortPreferences = true;
  }
  async savePreferences() {
    this.savingComfortPreferences = true;
    await this._stateManager.updateUserAsync(this.displayUser);
    this.editingComfortPreferences = false;
    this.savingComfortPreferences = false;
  }
  cancelPreferencesSave() {
    this.displayUser = structuredClone(this.stateUser);
    this.editingComfortPreferences = false;
  }

  async setDefaultCard(creditCard: any) {
    creditCard.isDefault = true;
    this.updatingCreditCard = true;
    await this._stateManager.updateSavedCreditCardAsync(creditCard);
    this.updatingCreditCard = false;
    await this._stateManager.getSavedCreditCardsAsync();
  }

  toggleComfortPreference(preference) {
    preference.isSelected = !preference.isSelected;
  }

  selectSubscriptionType(nameString) {
    this.displaySubscriptionType = this.subscriptionTypes.find(
      (st) => st.name == nameString
    );
  }
}
