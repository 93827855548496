import {
  IAppointment,
  IAppointmentRequest,
  IAppointmentType,
  IUser,
} from "../services/interfaces";
import { autoinject, observable, TaskQueue } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { StateManager } from "services/state-manager";
import { State } from "services/state";
import { connectTo } from "aurelia-store";
import moment from "moment-timezone";

@connectTo<State>()
@autoinject()
export class NewAppointmentDialog {
  controller: DialogController;
  _authenticationService: AuthenticationService;
  flatPickerObject: any;
  taskQueue: TaskQueue;
  stateManager: StateManager;

  appointmentTypes: IAppointmentType[];
  @observable selectedAppointmentType: IAppointmentType;

  patients: IUser[];
  selectedPatient: IUser;
  @observable patientSearchTerm = "";

  newAppointment: IAppointment = {
    userId: "",
    appointmentTypeId: "",
    appointmentRequestId: null,
    date: new Date(),
    durationInMinutes: 0,
    services: [],
  };
  appointmentRequest: IAppointmentRequest;

  constructor(
    controller: DialogController,
    authenticationService: AuthenticationService,
    taskQueue: TaskQueue,
    stateManager: StateManager
  ) {
    this.controller = controller;
    this._authenticationService = authenticationService;
    this.taskQueue = taskQueue;
    this.stateManager = stateManager;
  }

  selectedAppointmentTypeChanged() {
    this.newAppointment.appointmentTypeId =
      this.selectedAppointmentType.appointmentTypeId;
    this.newAppointment.durationInMinutes =
      this.selectedAppointmentType.durationInMinutes;
  }

  patientSearchTermChanged() {
    if (this.stateManager)
      this.stateManager.getPatientsAsync(this.patientSearchTerm);
  }

  async attached() {
    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
    this.stateManager.getAppointmentTypesAsync();
    this.stateManager.getPatientsAsync("");
  }

  stateChanged(state: State) {
    this.appointmentTypes = state.appointmentTypes;
    this.patients = state.patients;
  }

  createDatePicker() {
    var htmlElement = document.getElementById("new-appointment-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      altInput: true,
      altFormat: "F j, Y",
      minDate: "today",
      enableTime: true,
      onChange: (date) => {
        this.newAppointment.date = moment(date[0])
          .tz("America/Edmonton", true)
          .toDate();
      },
    });
  }

  async save() {
    this.newAppointment.userId = this.selectedPatient.userId;
    this.newAppointment.appointmentTypeId =
      this.selectedAppointmentType.appointmentTypeId;

    await this.stateManager.postAppointmentAsync(this.newAppointment);
    await this.stateManager.getAllAppointmentsAsync();

    this.controller.ok();
  }

  cancel() {
    this.controller.cancel();
  }
}
