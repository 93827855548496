import { ICreditCard, IUser } from "./../services/interfaces";
import { autoinject, TaskQueue } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { StateManager } from "services/state-manager";

@autoinject()
export class NewCreditCardDialog {
  controller: DialogController;
  _authenticationService: AuthenticationService;
  _stateManager: StateManager;
  user: IUser;

  newCreditCard: any = {
    cardNumber: "",
    creditCardType: "",
    yearExpiration: "",
    monthExpiration: "",
    cv2: "",
    billingContact: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      streetNumber: "",
      street: "",
      postalCode: "",
    },
  };

  constructor(
    controller: DialogController,
    authenticationService: AuthenticationService,
    stateManager: StateManager
  ) {
    this.controller = controller;
    this._authenticationService = authenticationService;
    this._stateManager = stateManager;
  }

  activate(user: IUser) {
    this.user = user;
  }

  fillCardholderInformation() {
    this.newCreditCard.billingContact.firstName = this.user.firstName;
    this.newCreditCard.billingContact.lastName = this.user.lastName;
    this.newCreditCard.billingContact.email = this.user.email;
    this.newCreditCard.billingContact.phoneNumber = this.user.mobilePhone;
  }

  async save() {
    this.controller.ok();
  }

  async submitCardInfo() {
    this.newCreditCard.creditCardType = this.getCreditCardType(
      this.newCreditCard
    );
    const fetchUrl = `/creditcards`;
    const returnedData = await fetch(fetchUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this._authenticationService.session.token}`,
      },
      body: JSON.stringify(this.newCreditCard),
    });

    try {
      const data = await returnedData.json();
    } catch (exception) {
      console.log("Error");
    }

    await this._stateManager.getSavedCreditCardsAsync();
    this.save();
  }

  cancel() {
    this.controller.cancel();
  }

  getCreditCardType(creditCard) {
    switch (creditCard.cardNumber[0]) {
      case "4":
        return "Visa";
      case "5":
        return "MasterCard";
      case "3":
        return "Amex";
      default:
        return "Unknown";
    }
  }
}
