import { autoinject, observable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { IAppointment, IUser } from "services/interfaces";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import "flatpickr/dist/flatpickr.css";
import { Router } from "aurelia-router";
import { DialogService } from "aurelia-dialog";
import { EditAppointmentDialog } from "modals/edit-appointment";
import { NewAppointmentDialog } from "modals/new-appointment";
import { NewClientDialog } from "modals/new-client";

@connectTo<State>()
@autoinject()
export class Clients {
  _stateManager: StateManager;
  _router: Router;
  _dialogService: DialogService;
  patients: IUser[];
  displayPatients: IUser[];
  isLoadingTableData: boolean = false;
  @observable patientSearchTerm: string = "";
  isSearching: boolean = false;

  constructor(
    stateManager: StateManager,
    router: Router,
    dialogService: DialogService
  ) {
    this._stateManager = stateManager;
    this._router = router;
    this._dialogService = dialogService;
  }

  stateChanged(state: State) {
    this.patients = state.patients;
    this.displayPatients = this.patients;
    this.isSearching = false;
    this.isLoadingTableData = false;
  }

  attached() {
    this.isLoadingTableData = true;
    this._stateManager.getPatientsAsync(this.patientSearchTerm);
  }

  navigateToPatient(user: IUser) {
    this._router.navigateToRoute("patient", { userId: user.userId });
  }

  patientSearchTermChanged() {
    this.isSearching = true;
    if (this._stateManager) {
      this._stateManager.getPatientsAsync(this.patientSearchTerm);
    }
  }

  openNewClientDialog() {
    this._dialogService
      .open({
        viewModel: NewClientDialog,
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }
  openNewAppointmentModal() {
    this._dialogService
      .open({
        viewModel: NewAppointmentDialog,
        model: {},
      })
      .whenClosed(async (response) => {
        if (!response.wasCancelled) {
        } else {
        }
      });
  }

  getBadgeColor(membershipString) {
    switch (membershipString) {
      case "Founder":
        return "ro-orange";
      case "Gold":
        return "ro-gold";
      case "Silver":
        return "ro-green";
      case "Bronze":
        return "ro-blue";
      default:
        return "primary";
    }
  }
}
