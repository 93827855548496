import { IAppointment, IAppointmentRequest } from "../services/interfaces";
import { autoinject, observable, TaskQueue } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import moment from "moment-timezone";

@autoinject()
export class NewAppointmentFromRequestDialog {
  controller: DialogController;
  _authenticationService: AuthenticationService;
  flatPickerObject: any;
  taskQueue: TaskQueue;

  newAppointment: IAppointment = {
    userId: "",
    appointmentTypeId: "",
    appointmentRequestId: "",
    date: new Date(),
    durationInMinutes: 0,
    services: [],
  };
  appointmentRequest: IAppointmentRequest;

  constructor(
    controller: DialogController,
    authenticationService: AuthenticationService,
    taskQueue: TaskQueue
  ) {
    this.controller = controller;
    this._authenticationService = authenticationService;
    this.taskQueue = taskQueue;
  }

  activate(appointmentRequest: IAppointmentRequest) {
    this.newAppointment.appointmentRequestId =
      appointmentRequest.appointmentRequestId;

    this.newAppointment.appointmentTypeId =
      appointmentRequest.appointmentType.appointmentTypeId;

    this.newAppointment.userId = appointmentRequest.user.userId;

    this.appointmentRequest = appointmentRequest;
  }

  attached() {
    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
  }

  createDatePicker() {
    var htmlElement = document.getElementById("new-appointment-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      altInput: true,
      altFormat: "F j, Y",
      inline: true,
      minDate: "today",
      enableTime: true,
      onChange: (date) => {
        this.newAppointment.date = moment(date[0])
          .tz("America/Edmonton", true)
          .toDate();
      },
    });
  }

  async save() {
    this.controller.ok(this.newAppointment);
  }

  cancel() {
    this.controller.cancel();
  }
}
