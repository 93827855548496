import {
  IAppointmentRequest,
  IAppointmentType,
  IUser,
} from "./../services/interfaces";
import { observable, autoinject, TaskQueue } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { AuthenticationService } from "services/authentication-service";
import { State } from "services/state";
import * as FlatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import { StateManager } from "services/state-manager";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { Router } from "aurelia-router";

@autoinject()
@connectTo<State>()
export class BookNowPage {
  _authenticationService: AuthenticationService;
  _stateManager: StateManager;
  _router: Router;

  createdAccount: IUser;
  appointmentTypes: IAppointmentType[];
  appointmentRequest: IAppointmentRequest = {
    dateCreated: new Date(),
    appointmentType: null,
    requestedAppointmentDates: [],
    requestedAppointmentTimesOfDay: [],
    user: null,
  };
  submittingAppointmentRequest: boolean = false;

  verificationEmailSent: boolean = false;

  previousButtonVisible: boolean = false;
  nextButtonVisible: boolean = false;

  currentView: string = "userInfo";

  firstName: string;
  lastName: string;
  phoneNumber: string;
  phoneError: string;

  taskQueue: TaskQueue;
  flatPickerObject: any;
  requestedAppointmentDates: Date[] = [];
  selectedAppointmentType: any;
  timeOfDaySelected: boolean = false;

  @observable emailAddress: string;
  emailError: string;
  emailVerified: boolean = false;

  requestError: string = "";

  timesOfDay = [
    { id: 1, name: "Mornings", isSelected: false },
    { id: 2, name: "Afternoons", isSelected: false },
    { id: 3, name: "Evenings", isSelected: false },
  ];

  constructor(
    authenticationService: AuthenticationService,
    taskQueue: TaskQueue,
    stateManager: StateManager,
    router: Router
  ) {
    this._authenticationService = authenticationService;
    this.taskQueue = taskQueue;
    this._stateManager = stateManager;
    this._router = router;
  }

  async attached() {
    const returnedData = await fetch("AppointmentTypes", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const jsonUnpackedData = await returnedData.json();

    this.appointmentTypes = jsonUnpackedData;
  }

  async confirmEmailAddress() {
    this.checkEmailAddress();
    this.checkPhoneNumber();

    this.verificationEmailSent = true;
    this.nextButtonVisible = true;

    const returnedData = await fetch("ShallowRegister", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email: this.emailAddress,
        phone: this.phoneNumber,
        firstName: this.firstName,
        lastName: this.lastName,
      }),
    });

    const jsonUnpackedData = await returnedData.json();

    this.createdAccount = jsonUnpackedData;
  }

  checkPhoneNumber() {
    if (!this.phoneNumber) {
      this.phoneError = "Please enter your phone number";
      return;
    }

    var phoneRegex = /^[0-9]{10}$/;
    if (this.phoneNumber.match(phoneRegex)) {
      this.phoneError = "";
    } else {
      this.phoneError = "Please enter a valid 10-digit phone number";
    }

    this.phoneError = "";
  }

  checkEmailAddress() {
    if (!this.emailAddress) {
      this.emailError = "Please enter your email address";
      return;
    }

    if (
      this.emailAddress.includes("@") === false ||
      this.emailAddress.includes(".") === false
    ) {
      this.emailError = "Please enter a valid email address";
      return;
    }
    this.emailError = "";
  }

  previous() {
    if (this.currentView === "bookAppointment") {
      this.currentView = "userInfo";
      this.previousButtonVisible = false;
      if (this.createdAccount) {
        this.emailAddress = this.createdAccount.email;
        this.firstName = this.createdAccount.firstName;
        this.lastName = this.createdAccount.lastName;
        this.phoneNumber = this.createdAccount.mobilePhone;
        this.nextButtonVisible = true;
      } else {
        this.nextButtonVisible = false;
      }
    }
  }

  next() {
    if (
      this.currentView === "userInfo" &&
      this.verificationEmailSent === true
    ) {
      this.currentView = "bookAppointment";
      this.previousButtonVisible = true;
      this.nextButtonVisible = false;
    }
  }

  selectAppointment(appointment) {
    this.selectedAppointmentType = null;

    if (this.flatPickerObject != null) {
      this.flatPickerObject.destroy();
      this.flatPickerObject = null;
    }

    this.appointmentTypes.forEach((a) => {
      a.isSelected = false;
    });

    appointment.isSelected = true;
    this.selectedAppointmentType = appointment;

    this.taskQueue.queueMicroTask(() => {
      this.createDatePicker();
    });
  }

  selectTimeOfDay(timeOfDay) {
    this.requestError = "";
    timeOfDay.isSelected = !timeOfDay.isSelected;
    this.timeOfDaySelected = this.timesOfDay.some((t) => t.isSelected == true);
  }

  async requestAppointment() {
    //Re Request the user. If the account isn't confirmed, block them.

    const url = `/ShallowRegister/${this.createdAccount.userId}`;

    const returnedUserData = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const jsonUnpackedUserData = await returnedUserData.json();

    if (jsonUnpackedUserData.emailConfirmed === false) {
      this.requestError =
        "Please confirm your email address before booking an appointment.";
      return;
    }

    const timeOfDay = this.timesOfDay.filter((t) => t.isSelected).length === 0;
    if (timeOfDay) {
      this.requestError = "Please select a time of day.";
      return;
    }

    this.requestError = "";

    this.appointmentRequest.user = this.createdAccount;
    this.appointmentRequest.requestedAppointmentDates =
      this.requestedAppointmentDates.map((date) => {
        return {
          date: moment(date).tz("America/Edmonton", true).toDate(),
        };
      });

    this.appointmentRequest.appointmentType = this.selectedAppointmentType;
    this.appointmentRequest.requestedAppointmentTimesOfDay = this.timesOfDay
      .filter((t) => t.isSelected)
      .map((t) => {
        return { timeOfDay: t.name };
      });

    this.submittingAppointmentRequest = true;

    const returnedData = await fetch("ShallowAppointmentRequests", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(this.appointmentRequest),
    });

    this.submittingAppointmentRequest = false;

    //Swal Fire Success Message.
    Swal.fire({
      title: `Request Submitted`,
      text: `Thank you for your request. We will reach out soon to book you in.`,
      showDenyButton: false,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: `Great!`,
      confirmButtonColor: "#40684e",
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.resetPage();
      }
    });

    this._router.navigateToRoute("register");
  }

  createDatePicker() {
    var htmlElement = document.getElementById("appointment-calendar");

    if (htmlElement == null) return;

    if (this.flatPickerObject != null) return;

    this.flatPickerObject = new (<any>FlatPickr)(htmlElement, {
      altInput: true,
      altFormat: "F j, Y",
      mode: "multiple",
      inline: true,
      minDate: "today",
      onChange: (newDates, dateStr, instance) => {
        this.requestedAppointmentDates = newDates;
      },
    });
  }
  resetPage() {
    this.currentView = "userInfo";
    this.previousButtonVisible = false;
    this.nextButtonVisible = false;
    this.verificationEmailSent = false;
    this.emailAddress = "";
    this.emailError = "";
    this.emailVerified = false;
    this.firstName = "";
    this.lastName = "";
    this.phoneNumber = "";
    this.phoneError = "";
    this.createdAccount = null;
    this.selectedAppointmentType = null;
    this.timeOfDaySelected = false;
    this.requestedAppointmentDates = [];
    this.flatPickerObject.destroy();
    this.flatPickerObject = null;
    this.appointmentRequest = {
      dateCreated: new Date(),
      appointmentType: null,
      requestedAppointmentDates: [],
      requestedAppointmentTimesOfDay: [],
      user: null,
    };
  }
}
